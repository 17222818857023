<div class="su_metrics_dates">
  <button class="cohorts_button" @click="get_cohorts">Traer Cohortes</button>
  <div class="su_metrics_dates_filter">
    <div class="su_metrics_dates_single">
      <p>Seleccione el mes inicial:</p>
      <Datepicker v-model="initial_month" monthPicker :minDate="new Date(2021,0)" :maxDate="max_month" />
    </div>
    <div class="from-to">
      <p>-></p>
    </div>
    <div class="su_metrics_dates_single">
      <p>Seleccione el mes final:</p>
      <Datepicker v-model="final_month" monthPicker :minDate="new Date(2021,0)" :maxDate="max_month" />
    </div>
    <div class="from-to">
      <p>-></p>
    </div>
    <button v-if="loaded" @click="filter_cohorts">Filtrar</button>
  </div>
</div>
<div v-if="loaded">
  <div class="su_graphs_graph">
    <h2>Factor de riesgo por conteo</h2>
    <GChart type="ColumnChart" :data="riskFactorCountChartData" :options="chartOptionsEngagement" />
  </div>
  <div>
    <input type="checkbox" id="inactivo" @change="filter_columns($event)" checked>
    <label for="inactivo">inactivo</label>
    <input type="checkbox" id="no_atraso" @change="filter_columns($event)" checked>
    <label for="no_atraso">no_atraso</label>
    <input type="checkbox" id="menor_30" @change="filter_columns($event)" checked>
    <label for="menor_30">menor_30</label>
    <input type="checkbox" id="menor_60" @change="filter_columns($event)" checked>
    <label for="menor_60">menor_60</label>
    <input type="checkbox" id="menor_90" @change="filter_columns($event)" checked>
    <label for="menor_90">menor_90</label>
    <input type="checkbox" id="menor_120" @change="filter_columns($event)" checked>
    <label for="menor_120">menor_120</label>
    <input type="checkbox" id="mayor_120" @change="filter_columns($event)" checked>
    <label for="mayor_120">mayor_120</label>
  </div>
  <button @click="downloadCSVCount">Descargar CSV de engagement por conteo</button>
</div>
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import InvestorsView from '../views/investors-view/InvestorsView.vue'
import CohortsView from '../views/cohorts/CohortsView.vue'
import CohortsLoanView from '../views/cohorts-loan/CohortsLoanView.vue'
import OtherMetricsView from '../views/other-metrics/OtherMetricsView.vue'
import RiskFactorView from '../views/risk-factor/RiskFactorView.vue'
import RiskFactorCountView from '@/views/risk-factor-count/RiskFactorCountView.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'investors',
    component: InvestorsView
  },
  {
    path: '/cohorts-loan',
    name: 'cohorts-loan',
    component: CohortsLoanView
  },
  {
    path: '/cohorts',
    name: 'cohorts',
    component: CohortsView
  },
  {
    path: '/individuals',
    name: 'individuals',
    component: OtherMetricsView
  },
  {
    path: '/risk-factor',
    name: 'risk-factor',
    component: RiskFactorView
  },
  {
    path: '/risk-factor-count',
    name: 'risk-factor-count',
    component: RiskFactorCountView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

import { defineComponent, ref } from "vue";
import { GChart } from "vue-google-charts";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
export default defineComponent({
  name: "RiskFactorCountView",

  components: {
    GChart,
    Datepicker
  },
  
  props: {
    token: {
      type: String,
      default: ""
    }
  },
  
  setup(props) {
    
    const totalriskFactorCountChartData = ref([[]])
    const riskFactorCountChartData = ref([[]])
    let bucket_visibilities = {"no_atraso":true, "menor_30":true,"menor_60":true, "menor_90":true, "menor_120":true, "mayor_120":true,"inactivo":true}
    let bucket_numbers = {"inactivo":0,"no_atraso":1, "menor_30":2,"menor_60":3, "menor_90":4, "menor_120":5, "mayor_120":6}
    const loaded = ref(false);
    const month_names = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]
    let data_indexes= {};
    
    const get_indexes = (data) =>{
      for (let i = 1; i < data.length; i++) {
        data_indexes[data[i][0].replace(" ","")] = i;
      }
    }
    
    const get_cohorts = () => {
      fetch(
        'https://7k6gnwamhi-sureti-admin-api.oiti.cloud:81/admin/metrics/risk_factor_count'
        ,{
        headers: {
          'Authorization': `Bearer ${props.token}`,
        },
      }
      )
        .then(res => res.json())
        .then(data => {
          
          totalriskFactorCountChartData.value = data.data.general;
          riskFactorCountChartData.value = data.data.general;
          console.log(data.data.general); 
          //get_indexes(data.data.active_investors);
          
          if (Object.keys(data.data).length  != 0){
            loaded.value = true;
          }
        })
        .catch(err => {
          console.log(err.message);
          loaded.value = false;
        });
    }

    const downloadCSVCount = () => {
      downloadCSVData(riskFactorCountChartData, "mean_investments_cohort.csv")
    }
    const downloadCSVData = (chartData, filename) => {
      let csv = chartData.value[0].toString() + "\n";
      let justData = chartData.value.slice(1);
      justData.forEach((row) => {
        csv += row.join(',');
        csv += "\n";
      });
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = filename;
      anchor.click();
    }
    const filter_columns = (e) =>{
      console.log(e.target.id);
      bucket_visibilities[e.target.id] = !bucket_visibilities[e.target.id]
      let indexes_to_include = [0]
      for (const bucket_visible of Object.keys(bucket_visibilities)) {
        if(bucket_visibilities[bucket_visible]){
          indexes_to_include.push((bucket_numbers[bucket_visible]*3)+1)
          indexes_to_include.push((bucket_numbers[bucket_visible]*3)+2)
          indexes_to_include.push((bucket_numbers[bucket_visible]*3)+3)
        }
      }
      indexes_to_include.sort(function(a,b) {
        return (+a) - (+b);
      })
      console.log(indexes_to_include);
      riskFactorCountChartData.value = totalriskFactorCountChartData.value.map(row => indexes_to_include.map(index=>row[index])) 
    }

    const filter_cohorts= () =>{
      let year_difference = final_month.value.year - initial_month.value.year;
      let month_difference = (year_difference * 12) + final_month.value.month - initial_month.value.month;
      let key = month_names[initial_month.value.month]+","+initial_month.value.year;
      let start_index = data_indexes[key];
      riskFactorCountChartData.value = []
      riskFactorCountChartData.value.push(totalriskFactorCountChartData.value[0])
      for (let i = 0; i < month_difference; i++) {
        riskFactorCountChartData.value.push(totalriskFactorCountChartData.value[start_index+i])
      }
    }
    const cssClassNames = {
      headerCell: 'header-cell',
      tableRow: 'table-row',
      oddTableRow: 'odd-table-row',
      tableCell: 'table-cell'
    }

    const chartOptions1 = {
      width: "100%",
      height: 600,
      'cssClassNames': cssClassNames,
    }

    const chartOptionsEngagement = {
      width: "100%",
      height: 600,
      chartArea: { left: 40, right: 120, top: 40, bottom: 40, width: "95%" },
      colors: ['#3367fb','#7094fb', '#a7befe', '#2cff46', '#77fe88', '#befec6',
               '#fdf317','#fef877','#fdfab9','#fa941a','#fcbd72','#feddb4',
               '#ff2116','#fe807a','#febebb','#fd25f0','#fd7df5','#fcbff9',
                '#000000','#474747','#909090'
              ],
      isStacked: true

    }

    const chartOptions2 = {
      backgroundColor: '#F5F4FF',
      width: "100%",
      height: 600,
      chartArea: { left: 40, right: 120, top: 40, bottom: 40, width: "95%" },
      lineDashStyle: [4, 4],
      axes: {
        x: {
          0: { side: 'bottom' }
        }
      }
    }
    
    const initial_month = ref({
      month: 0,
      year: 2021
    });
    const max_month = new Date(new Date().getFullYear(), new Date().getMonth())
    const final_month = ref({
      month: new Date().getMonth()-1,
      year: new Date().getFullYear()
    });
    
    return {
      chartOptions1,
      chartOptions2,
      chartOptionsEngagement,
      riskFactorCountChartData,
      final_month,
      max_month,
      initial_month,
      loaded,
      get_cohorts,
      downloadCSVCount,
      filter_columns,
      filter_cohorts
    };
  },
});  

<template>
  <div class="su_metrics">
    <div class="su_metrics_sidebar">
      <h2>Sureti Metrics</h2>
      <nav>
        <router-link to="/">Inversionistas <span>-></span></router-link> 
        <router-link to="/cohorts">Cohortes <span>-></span></router-link> 
        <router-link to="/cohorts-loan">Cohortes Préstamos <span>-></span></router-link> 
        <router-link to="/risk-factor">Factor de Riesgo <span>-></span></router-link>
        <router-link to="/risk-factor-count">Factor de Riesgo Conteo<span>-></span></router-link>
        <router-link to="/individuals">Otras Métricas <span>-></span></router-link>

        <input v-model="token" type="text" placeholder="Token">
      </nav>
    </div>
    <div class="content">
      <router-view :token="token"/>
    </div>
  </div>
</template>

<script>
import { defineComponent,ref } from "vue";
export default defineComponent({
  name: "HomeView",
  
  components: {
  }, 
  setup(){
    const token =ref();
    return{
      token
    }
  }
  
});
</script>

<style lang="scss">
//UTILITIES
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

//GENERAL STYLES
* {
  font-family: 'Inter';
}

//HOME STYLES

.su_metrics {
  display: flex;
  height: 100vh;
  &_sidebar {
    padding: 1rem;
    height: 100%;
    background-color: #f5f4ff;
    h2 {
      margin: 0;
      padding: 1.5rem 0 1rem;
      color: #514ac6;
      border-bottom: 1px solid #514ac6;
    }
    nav {
      display: flex;
      flex-direction: column;
      a {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        text-decoration: none;
        color: #746ed1;
        border-bottom: 1px solid #dcdbf4;
        transition: 0.25s ease-in-out;

        span {
          display: none;
        }
        &.router-link-active {
          color: #514ac6;
          font-weight: 700;
          padding-left: 1rem;
          border-left: 4px solid #ffe102;
          span {
            display: flex;
          }
        }
      }
      input {
        margin-top: 1rem;
        padding: 0.5rem 1rem;
      }
    }
  }
  .content {
    display: flex;
    padding: 1.5rem;
    width: 100%;
    flex-direction: column;
  }
  &_dates {
    display: flex;
    justify-content: center;
    align-items: center !important;;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #746ed1;
    gap: 1.6rem;
    .cohorts-button {
      flex: 2;
      margin-bottom: 15px;
      background-color: #8676fa;
      &:active, &:hover {
        background-color: #ffe102;
      }
    }
    &_filter {
      flex: 8;
      display: flex;
      gap: 1rem;
      padding: 1rem;
      border-radius: 5px;
      background-color: #dcdbf4;
    }

    &_single {
      flex: 1;
      display: flex;
      flex-direction: column;
      p {
        margin: 0 0 0.25rem;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #746ed1;
      }
      input {
        border-color: #746ed1;
      }
    }
    .from-to {
      align-self: flex-end;
      p {
        margin: 0;
        padding-bottom: 12px;
        color: #746ed1;
      }
    }
  }
  
}
button {
      flex: 1;
      height: 40px;
      padding: 0.5rem 1rem;
      align-self: flex-end;
      background-color: #514ac6;
      border: none;
      color: #ffffff;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.25s ease-in-out;
      &:active, &:hover {
        color: #514ac6;
        background-color: #ffe102;
      }
    }
.su_graphs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
  &_graph {
    flex: 1;
    .header-cell {
      background-color: #514ac6;
      color: #dcdbf4;
        @media (min-width: 1200px) {
          padding: 0.5rem !important;
        }
        &:first-child {
          text-align: left;
        }
    }
    .odd-table-row {
      background-color: #f5f4ff;
    }
    .table-cell {
      text-align: center;
      @media (min-width: 1200px) {
        padding: 0.5rem !important;
      }
      &:first-child {
        text-align: left;
        border-left: 1px solid #eee;
      }
    }
  }
}
</style>
